<template>
  <div class="container">
      <h1>Privacy Policy</h1>
      <h3>Last updated: 27.06.2020</h3>
      <p>
        We at usbhubs.org offer a simple comparison service so that you can find your perfect USB-Hub. 
      </p>
      <p>
        We use a <a href="https://plausible.io/" target="_bank">Plausible</a> to measure the effectiveness of our website.<b>We don't share any private information from you with third parties.</b>
        </p>

    </div>
</template>

<script>
export default {
  name: "privacy"
};
</script>
