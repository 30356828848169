import { Entry } from "@/types/Entry";
import Vue from 'vue';

const cache: {[k: string]: Entry[]} = {}

class DataService {
    public static async isSupportedType(selectedStore: string) {
        return (selectedStore === 'usbc') || (selectedStore === 'thunderbolt3')
    }
    public static async getUSBCHubs(selectedStore: string): Promise<Entry[]> {
        if (typeof cache[`${selectedStore}_usbc`] === 'undefined') {
            const response = await Vue.axios.get(`/data/${selectedStore}/usbc`)
            cache[`${selectedStore}_usbc`] = response.data
        }
        return cache[`${selectedStore}_usbc`]
    }
    public static async getTB3Hubs(selectedStore: string): Promise<Entry[]> {
        if (typeof cache[`${selectedStore}_tb3`] === 'undefined') {
            const response = await Vue.axios.get(`/data/${selectedStore}/tb3`)
            cache[`${selectedStore}_tb3`] = response.data
        }
        return cache[`${selectedStore}_tb3`]
    }
}

export { DataService }
