
import Vue from "vue";
import { DataService } from "../services/DataService";
import AppliedFilters from './AppliedFilters.vue'
import NumberInput from './NumberInput.vue'
import DetailedProductModal from './DetailedProductModal.vue'
import { AffiliateHelper } from "../services/AffiliateHelper";
import {
  Entry,
  Ethernet,
  VideoResolution,
  Adapter,
  Resolution
} from "../types/Entry";

const filterData = {
    pd_selected: {short: 'PD', default: undefined},
    sixtyHz4k: {short: '60Hz4K', default: 0},
    vga_selected:{short: 'VGA', default: undefined},
    selectedEthernet: {short: 'Ethernet', default: Ethernet.None},
    headphone_selected: {short: 'Headphone', default: undefined},
    sd_selected: {short: 'SD', default: undefined},
    tf_selected: {short: 'TF', default: undefined},
    usbC: {short: 'USB-C', default: 0},
    usb3: {short: 'USB-3', default: 0},
    usb2: {short: 'USB-2', default: 0},
    hdmi: {short: 'HDMI', default: 0},
    dp: {short: 'DP', default: 0},
    tb3: {short: 'Thunderbolt 3', default: 0},
}

export default Vue.extend({
  name: "USBC",
  components: {
    AppliedFilters,
    DetailedProductModal,
    NumberInput
  },
  data: function() {
    return {
      test: ['foobar','loreum'],
      entries: <Entry[]>[],
      filteredEntries: <Entry[]>[],
      filterTexts: {
        pd_selected: {short: 'PD'},
        sixtyHz4k: {short: '60Hz4K'},
        vga_selected:{short: 'VGA'},
        selectedEthernet: {short: 'Ethernet'},
        headphone_selected: {short: 'Headphone'},
        sd_selected: {short: 'SD'},
        tf_selected: {short: 'TF'},
        usbC: {short: 'USB-C'},
        usb3: {short: 'USB-3'},
        usb2: {short: 'USB-2'},
        hdmi: {short: 'HDMI'},
        dp: {short: 'DP'},
        tb3: {short: 'Thunderbolt 3'},
      },
      filters: {
        pd_selected: undefined,
        sixtyHz4k: 0,
        vga_selected: undefined,
        selectedEthernet: Ethernet.None,
        headphone_selected: undefined,
        sd_selected: undefined,
        tf_selected: undefined,
        usbC: 0,
        usb3: 0,
        usb2: 0,
        hdmi: 0,
        dp: 0,
        tb3: 0,
      },
      selectedHubType: "usbc",
      selectedStore: "us",
      linkToggleDescription: "Turn off affiliate links",
      affiliateLinksOn: true,
      hubTypes: [
        {
          value: "usbc",
          text: "USB-C Hubs"
        },
        {
          value: "thunderbolt3",
          text: "Thunderbolt 3 Hubs"
        }
      ],
      stores: [
        {
          value: "us",
          text: "Amazon.com"
        },
        {
          value: "uk",
          text: "Amazon.co.uk"
        },
        {
          value: "ca",
          text: "Amazon.ca (Coming soon)"
        },
        {
          value: "es",
          text: "Amazon.es (Coming soon)"
        }
      ],
      ethernetTypes: [
        { value: Ethernet.None, text: "" },
        { value: Ethernet.Mega, text: "Min. 100 MBit" },
        { value: Ethernet.Giga, text: "Min. 1000 MBit" }
      ],
      choosenProduct: {} as Entry
    };
  },
  created() {
    const type = this.$route.params.type
    const store = this.$route.params.store
    const id = this.$route.params.id
    if (typeof store === 'undefined' || typeof type === 'undefined') {
      this.loadDefault();
    } else {
      if (!DataService.isSupportedType(type)) {
        this.loadDefault()
      } else {
        // load data according to store and type
        this.loadSpecific(store, type, id)
      }
    }
  },
  methods: {
    loadDefault: async function() {
      this.entries = await DataService.getUSBCHubs(this.selectedStore);
      this.modifyProductLinks();
      this.filteredEntries = this.entries;
    },
    loadSpecific: async function(store: string, type: string, id?: string) {
      this.selectedStore = store
      if (type === "usbc") {
        this.entries = await DataService.getUSBCHubs(store);
        this.selectedHubType = 'usbc'
      } else {
        this.entries = await DataService.getTB3Hubs(store);
        this.selectedHubType = 'thunderbolt3'
      }
      this.modifyProductLinks();
      this.filter();
      if (id) {
        this.findAndSelectProduct(id)
      }
    },
    changeHubs: async function() {
      if (this.selectedHubType === "usbc") {
        this.entries = await DataService.getUSBCHubs(this.selectedStore);
        this.$router.replace(`/${this.selectedStore}/usbc`)
      } else {
        this.entries = await DataService.getTB3Hubs(this.selectedStore);
        this.$router.replace(`/${this.selectedStore}/thunderbolt3`)
      }
      this.modifyProductLinks();
      this.filter();
    },
    showFilter: function(
      userHasSelected: boolean,
      feature: boolean,
      log: boolean = false
    ) {
      if (userHasSelected && feature === false) {
        return false;
      }
      return true;
    },
    changeLinks: function() {
      if (this.affiliateLinksOn === false) {
        this.affiliateLinksOn = true;
        this.linkToggleDescription = "Turn off affiliate links";
      } else {
        this.affiliateLinksOn = false;
        this.linkToggleDescription = "Turn on affiliate links";
      }
      this.modifyProductLinks();
    },
    modalHidden() {
      this.$router.replace(`/${this.selectedStore}/${this.selectedHubType}`)
    },
    findAndSelectProduct(id: string) {
      const product = this.entries.find(item => item.asin === id)
      if (product) {
        this.showProduct(product)
      }
    },
    showProduct(product: Entry) {
      this.choosenProduct = product
      this.$bvModal.show('modal-1')
      this.$router.replace(`/${this.selectedStore}/${this.selectedHubType}/${product.asin}`)
    },
    modifyProductLinks: function() {
      this.entries = AffiliateHelper.modifyProductLinks(this.entries, this.selectedStore, this.affiliateLinksOn)
      this.filter();
    },
    filterEthernet: function(
      selectedEthernet: Ethernet,
      deviceEthernet: Ethernet
    ) {
      if (selectedEthernet === Ethernet.None) {
        return true;
      }
      if (selectedEthernet === deviceEthernet) {
        return true;
      }
      if (
        selectedEthernet === Ethernet.Mega &&
        deviceEthernet === Ethernet.Giga
      ) {
        return true;
      }
      return false;
    },
    filterHdmi: function(
      numberOfHdmiPorts: number,
      videoPorts: VideoResolution[]
    ) {
      return (
        videoPorts.filter(res => res.adapter === Adapter.HDMI).length >=
        numberOfHdmiPorts
      );
    },
    filterDP: function(numberOfDPPorts: number, videoPorts: VideoResolution[]) {
      return (
        videoPorts.filter(res => res.adapter === Adapter.DP).length >=
        numberOfDPPorts
      );
    },
    filterTB3: function(
      numberOfTB3Ports: number,
      videoPorts: VideoResolution[]
    ) {
      return (
        videoPorts.filter(res => res.adapter === Adapter.TB3).length >=
        numberOfTB3Ports
      );
    },
    filter4K60: function(
      numberOf40K60Ports: number,
      videoPorts: VideoResolution[]
    ) {
      return (
        videoPorts.filter(res => res.resolution === Resolution["4K60"])
          .length >= numberOf40K60Ports
      );
    },
    filter: function() {
      const pdSelected = this.filters.pd_selected === "yes";
      const vgaSelected = this.filters.vga_selected === "yes";
      const headphoneSelected = this.filters.headphone_selected === "yes";
      const tfSelected = this.filters.tf_selected === "yes";
      const sdSelected = this.filters.sd_selected === "yes";

      this.filteredEntries = this.entries.filter(entry => {
        if (
          this.showFilter(pdSelected, entry.features.pd) &&
          this.filterEthernet(this.filters.selectedEthernet, entry.features.ethernet) &&
          this.showFilter(vgaSelected, entry.features.vga) &&
          this.showFilter(headphoneSelected, entry.features.headphone) &&
          this.showFilter(tfSelected, entry.features.tf) &&
          this.showFilter(sdSelected, entry.features.sd) &&
          this.filterHdmi(this.filters.hdmi, entry.features.video) &&
          this.filterDP(this.filters.dp, entry.features.video) &&
          this.filterTB3(this.filters.tb3, entry.features.video) &&
          this.filter4K60(this.filters.sixtyHz4k, entry.features.video) &&
          this.filters.usbC <= entry.features.usbc &&
          this.filters.usb3 <= entry.features.usb3 &&
          this.filters.usb2 <= entry.features.usb2
        ) {
          return true;
        }
        return false;
      });
    },
    removeFilter: function(key: {key: string}) {
      // @ts-ignore
      this.filters[key.key] = filterData[key.key].default
      this.filter()
    },
    recordGoal() {
      if (this.affiliateLinksOn) {
        (window as any).plausible('Clicked Amazon Link With Affiliate')
      } else {
        (window as any).plausible('Clicked Amazon Link Without Affiliate')
      }
    }
  }
});
