interface Entry {
    title: string
    price: string
    url: string
    imgUrl: string
    reviews: string
    noReviews: string
    asin: string
    features: Features,
    noFeatures?: number,
    popular?: boolean
}

interface Features {
    usbc: number
    usb3: number
    usb2: number
    video: VideoResolution[]
    ethernet: Ethernet
    pd: boolean
    vga: boolean
    headphone: boolean
    sd: boolean
    tf: boolean
}

enum Ethernet {
    None = '0',
    Mega = '100',
    Giga = '1000'
}

enum Adapter {
    DP = 'DisplayPort 1.2',
    mDP = 'miniDisplayPort',
    TB3 = 'Thunderbolt 3',
    HDMI = 'HDMI'

}

enum Resolution {
    '4K60' = '4K@60Hz',
    '4K30' = '4K@30Hz'
}

interface VideoResolution {
    adapter: Adapter
    resolution: Resolution
}

interface HDMI {
    count: number,
    '4k30': number,
    '4k60': number
}

export { Entry, Features, Ethernet, Adapter, Resolution, VideoResolution }