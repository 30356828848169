
import Vue from 'vue';
export default Vue.extend({
  name: 'number-input',
  props: {
      label: String,
      value: Number
  },
  computed: {
      selector() {
          return this.label.replace(/[\@\s#]/mgi, '-').slice(1)
      }
  },
  methods: {
      test(event: any) {
          this.$emit('input', parseInt(event,10))
      }
  }
});
