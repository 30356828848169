import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { LinkPlugin, ModalPlugin, NavPlugin, NavbarPlugin, CardPlugin, ImagePlugin, FormSelectPlugin, FormPlugin, IconsPlugin, LayoutPlugin, FormGroupPlugin, FormInputPlugin, FormRadioPlugin, ButtonPlugin, FormCheckboxPlugin, CollapsePlugin, BIconChevronCompactDown, BadgePlugin } from 'bootstrap-vue'
import './custom.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
 
Vue.use(VueAxios, axios)

Vue.config.productionTip = false;

Vue.use(CardPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(LayoutPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(ButtonPlugin)
Vue.use(CollapsePlugin)
Vue.use(IconsPlugin)
Vue.use(FormPlugin)
Vue.use(ImagePlugin)
Vue.use(FormSelectPlugin)
Vue.use(BadgePlugin)
Vue.use(ModalPlugin)
Vue.use(LinkPlugin)
Vue.component('BIconChevronCompactDown', BIconChevronCompactDown)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
