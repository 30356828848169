
import Vue from "vue";
import {
  Entry,
  Ethernet,
  VideoResolution,
  Adapter,
  Resolution
} from "../types/Entry";

export default Vue.extend({
  name: "Detailed",
  props: {
    product: {
      type: Object as () => Entry,
      required: true
    }
  },
  methods: {
    linkClicked() {
      this.$emit('amazon-link-clicked')
    }
  }
});
