
import Vue from "vue";

export default Vue.extend({
    name: 'applied-filters',
    props: {
        filters: Object,
        texts: {}
    },
    data: function(){
        return {
            originalFilterdata: Object,
            keys: Array<string>(),
            show: false
        }
    },
    created() {
        this.originalFilterdata = JSON.parse(JSON.stringify(this.filters))
        this.keys = Object.keys(this.filters)
    },
    computed: {
        filtersToShow: function() {
            const toShow = {}
            this.keys.forEach((key: string) => {
                // @ts-ignore
                if (this.filters[key] !== this.originalFilterdata[key]) {
                    // @ts-ignore
                    toShow[key] = this.filters[key]
                }
            })
            return toShow
        }
    },

    methods: {
        remove(keyOfObject: string) {
            this.$emit('removefilter', {key: keyOfObject})
        },
        createValue(value: boolean|string) {
            if (typeof value === 'undefined') {
                return ''
            }
            return `${value}`
        }
    }
})
