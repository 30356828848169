<template>
  <div class="container">
      <h1>Affiliate Disclosure</h1>

      <p>
        This website contains links to some third party sites which are described as affiliate links.
        These affiliate links allow us to gain a small commission when you click and buy products on those sites (it doesn't cost you anything extra!).
      </p>

      <p>Usb-Hubs is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for website owners to earn advertising fees by advertising and linking to amazon.com and any other website that may be affiliated with Amazon Service LLC Associates Program.</p>
    </div>
</template>

<script>
export default {
  name: "affiliate"
};
</script>
