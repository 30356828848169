<template>
  <div class="container text-left">
    <h2 class="mt-4">What is the Difference between Thunderbolt-3 and USB-C?</h2>
    <h3>Short Version</h3>
    <p>Thunderbolt 3 and USB-C share the same connector, that's why they look the same. But internally they use different software protocols. Thunderbolt 3 is much more powerful, as it can transfer a lot more data than USB-C, but it needs to be licensed from Intel (and not everyone manufacturer wants to pay Intel the fees).</p>
    <h3>"Longer" Version</h3>
    <p>Technically, there is no USB-C protocol, but only a USB-C connector and devices using the USB-C connector can use all kinds of version of the USB-protocol. Nowadays we strongly recommend to buy a device which uses the USB 3.1 Gen2 protocol to ensure that you have enough bandwith.</p>
    <p>Speaking of bandwidth: Thunderbolt 3 is capable of up to 40 GBit/s, whereas the most powerful USB 3.1 Gen2 can deliver 10GBit/s. This is also one of the reasons which no USB-C offers proper support for 4K displays with 60HZ refresh rate - these displays simply need too much bandwith than USB 3.1 Gen2 can offer.</p>
  <h3>Which Computers Include Thunderbolt 3?</h3>
  <p>All Apple laptops have Thunderbolt 3 ports built in. The exact amount depends on the device (usually the more expensive the more ports). Apart from Apple laptops there are a few other laptops which offer Thunderbolt 3 ports: 
    <ul>
      <li><a href="https://www.amazon.com/Alienware-Gaming-Notebook-Intel-i7-9750H/dp/B086DD63LZ/ref=as_li_ss_tl?crid=30E7MORD0R4R6&dchild=1&keywords=alienware+m17&qid=1591195347&sprefix=Alienware+M17,aps,303&sr=8-5&linkCode=ll1&tag=usbhubs0b-20&linkId=68b515dad0e385f197a8048d02ac043f" target="_blank">Dell Alienware M17</a>*</li>
      <li><a href="https://www.amazon.com/UX391UA-XB71-R-Ultra-thin-13-3-inch-i7-8550U-Thunderbolt/dp/B07D9C2YF5/ref=as_li_ss_tl?dchild=1&keywords=Asus+ZenBook+S+UX391&qid=1591196539&sr=8-1&linkCode=ll1&tag=usbhubs0b-20&linkId=c68b84eac6f6f952f08fd45f66e164d6" target="_blank">ASUS ZenBook S UX391UA</a>*</li>
    </ul>
  </p>

  <h3>Outlook</h3>
  <p>USB4 is on the horizon which will match the speed of Thunderbolt 3, so we will hopefully soon have a world where everyone lives peacefully using the same connector and protocol.</p>

  <h4>Further Readings</h4>
  <ul>
   <li> <a href="https://www.howtogeek.com/449991/thunderbolt-3-vs.-usb-c-whats-the-difference/" target="_blank">HowToGeek Article</a></li>
    <li><a href="https://www.imore.com/whats-difference-between-usb-c-and-thunderbolt" target="_blank">Imore Article</a></li>
  </ul>
  <small>* = Affiliate Links, see our <a href="/affiliate-disclosure">Affiliate Disclosure</a></small>
  </div>
</template>

<script>

export default {
  name: 'home',
 
};
</script>

<style scoped>
h3 {
  margin-top: 40px;
}
</style>
