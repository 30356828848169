<template>
  <div>
    <USBC/>
  </div>
</template>

<script>
import USBC from '@/components/USBC.vue';

export default {
  name: 'home',
  components: {
    USBC,
  },
};
</script>
