import { Entry } from '@/types/Entry';

const UsAffiliateId = "usbhubs0b-20";
const UkAffiliateId = "usbhubuk20-21";
const EsAffiliateId = "usbhubuk200f-21";
const CaAffiliateId = "usbhubca20-20";

class AffiliateHelper {

    private static getAffiliateIdForStore(store: string) {
        switch(store) {
            case 'us':
                return UsAffiliateId;
            case 'uk':
                return UkAffiliateId;
            case 'es':
                return EsAffiliateId;
            case 'ca':
                return CaAffiliateId;
            default:
                throw new Error('No matching affiliate Id')
        }
    }
    public static modifyProductLinks(entries: Entry[], store: string, affiliateLinksOn: boolean) {
        if (affiliateLinksOn) {
            const affiliateId = AffiliateHelper.getAffiliateIdForStore(store)
            entries.forEach(entry => {
              entry.url = entry.url.replace(/\?.*/, `?tag=${affiliateId}`);
            });
          } else {
            entries.forEach(entry => {
              entry.url = entry.url.replace(/\?.*/, "?");
            });
          }
          return entries
    }
}

export {AffiliateHelper}