import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Affiliate from '../views/Affiliate.vue'
import Privacy from '../views/Privacy.vue'
import Blog1 from '../views/TB3-USBC.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/:store/:type',
    name: 'home',
    component: Home,
  },
  {
    path: '/:store/:type/:id',
    name: 'home',
    component: Home,
  },
  {
    path: '/thunderbolt3-vs-usbc',
    name: 'tb3-vs-usbc',
    component: Blog1
  },
  {
    path: '/affiliate-disclosure',
    name: 'affiliate',
    component: Affiliate
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
